import type {
  EditorReadyFn,
  GetAppManifest,
} from '@wix/editor-platform-sdk-types'
import type { AppManifestBuilder } from '@wix/app-manifest-builder'

export const editorReady: EditorReadyFn = () => {}

export const getAppManifest: GetAppManifest<AppManifestBuilder> = ({
  appManifestBuilder,
}) => appManifestBuilder.build()
